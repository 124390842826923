<!--
File: DeuEditForm.vue
Description: form for adding/editing a single DEU.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <RegionsDropdown :class="[{ 'md-valid': !errors.has('fk_region') }, { 'md-error': errors.has('fk_region') }]"
            :label="$t('road_network.region')" v-model="fk_region" data-vv-name="fk_region"
            v-validate="modelValidations.fk_region" :initial_value="fk_region" :is_required="true" />
          <md-field :class="[{ 'md-valid': !errors.has('description') }, { 'md-error': errors.has('description') }]">
            <label>{{ $t('stdCols.name_ru') }}</label>
            <div style="display: flex; align-items: center;">
              <span>ДЭУ-</span>
              <md-input v-model="description" type="text" required data-vv-name="description"
                v-validate="modelValidations.description" />
            </div>
          </md-field>
          <md-field :class="[
            { 'md-valid': !errors.has('description_en') }, { 'md-error': errors.has('description_en') }]">
            <label>{{ $t('stdCols.name_en') }}</label>
            <div style="display: flex; align-items: center;">
              <span>DEU-</span>
              <md-input v-model="description_en" type="text" required data-vv-name="description_en"
                v-validate="modelValidations.description_en" style="flex: 1; border-left: none;" />
            </div>
          </md-field>
          <md-field :class="[{ 'md-valid': !errors.has('address1') }, { 'md-error': errors.has('address1') }]">
            <label>{{ $t('stdCols.address') + ' (1)' }}</label>
            <md-input v-model="address1" type="text" required data-vv-name="address1"
              v-validate="modelValidations.address1" />
          </md-field>
          <md-field :class="[{ 'md-valid': !errors.has('address2') }, { 'md-error': errors.has('address2') }]">
            <label>{{ $t('stdCols.address') + ' (2)' }}</label>
            <md-input v-model="address2" type="text" data-vv-name="address2" />
          </md-field>
          <md-field :class="[{ 'md-valid': !errors.has('city') }, { 'md-error': errors.has('city') }]">
            <label>{{ $t('road_network.city') }}</label>
            <md-input v-model="city" type="text" required data-vv-name="city" v-validate="modelValidations.city" />
          </md-field>
        </div>

        <span v-if="saveBtnDisabled" class="md-error">
          {{ $t('messages.errors_count', { count: errors.count() }) }}
        </span>

        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import Swal from 'sweetalert2'
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import { onClose } from '@/mixins/onCloseMixin'

export default {
  name: 'deu-edit-form',
  data() {
    return {
      deu_id: null,
      fk_region: null,
      description: null,
      description_en: null,
      address1: null,
      address2: null,
      city: null,
      saved_successfully: false,

      modelValidations: {
        fk_region: { required: true, numeric: true, min_value: 1 },
        description: { required: true, min: 1 },
        description_en: { required: true, min: 1 },
        address1: { required: true, min: 3 },
        address2: { required: false, min: 3 },
        city: { required: true, min: 3 },
      }
    }
  },

  props: {
    oper: String
  },

  components: {
    RegionsDropdown,
  },

  created() {
    const { deu_id = null } = this.$route.params
    this.deu_id = deu_id
    if (this.oper === 'upd' && deu_id) {
      this.$store.dispatch('LOAD_DEU_BY_ID', deu_id).then((res) => {
        const theDeu = Array.isArray(res) ? res[0] : res
        this.fk_region = theDeu.fk_region
        this.description = theDeu.description
        this.description_en = theDeu.description_en
        this.address1 = theDeu.address1
        this.address2 = theDeu.address2
        this.city = theDeu.city
      })
    }
    this.$nextTick(() => {
      this.$validator.validateAll()
    })
  },

  methods: {
    /* onClose() {
       localStorage.setItem('history_for', (this.oper === 'add') && (this.saved_successfully) === true ? '' : 'deus')
       this.$router.push('/inventory_data/deu')
     },
 */onClose,
    async validate() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return

      const alert = {
        icon: isValid ? 'success' : 'error',
        text: `${this.$t('road_network.dep')}${this.$t(`label.was_${isValid ? '' : 'not_'}saved`, {
          name_ru: this.description,
          name_en: this.description_en
        })}`,
        footer: ''
      };

      const theDeu = {
        fk_region: this.fk_region,
        description: this.description,
        description_en: this.description_en,
        address1: this.address1,
        address2: this.address2,
        city: this.city
      };

      const reqData = this.oper === 'add' ? theDeu : { id: this.deu_id, theDeu };
      const action = `${this.oper.toUpperCase()}_DEU`;
      try {
        await this.$store.dispatch(action, reqData);
        this.saved_successfully = true;
      } catch (err) {
        alert.footer = err; // Вставляем ошибку в футер
      }

      await Swal.fire(alert);
      this.$nextTick(() => {
        this.$validator.reset()
      })
      this.onClose();
    }
  },

  computed: {
    saveBtnDisabled() {
      return this.errors.count() > 0
    }
  }
}
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>